import React from "react"

import { Col, Table } from "react-bootstrap"

import {
  dollarFormatter,
  numberFormatter,
  numberFormatterTenths,
  percentFormatter,
  numberFormatterHundredths,
} from "../utils"

const PrototypeResultsCol = (props) => {
  const { prototype } = props
  if (prototype && prototype.totalResidualLandValue) {
    return (
      <Col sm="12" md="6">
        <div className="card text-white bg-secondary mb-3">
          <h5 className="card-header">Physical Results</h5>
          <div className="card-body">
            <Table className="text-white">
              <tbody>
                <tr>
                  <td>
                    Building Coverage
                    <br />
                    <small>
                      The portion of the site which is covered by the structure.
                    </small>
                  </td>
                  <td>{percentFormatter.format(prototype.buildingCoverage)}</td>
                </tr>
                <tr>
                  <td>
                    Parking Coverage
                    <br />
                    <small>
                      The portion of the site which is covered by surface
                      parking.
                    </small>
                  </td>
                  <td>
                    {percentFormatter.format(prototype.imperviousCoverage)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Landscape Coverage
                    <br />
                    <small>
                      Remaining Area for Landscaping. If negative, parking does
                      not fit on site.
                    </small>
                  </td>
                  <td>
                    {percentFormatter.format(prototype.landscapeCoverage)}{" "}
                    {prototype.landscapeCoverage <= 0 &&
                      "Site size is insufficient"}
                  </td>
                </tr>
                <tr>
                  <td>
                    Density
                    <br />
                    <small>The resulting development density.</small>
                  </td>
                  <td>
                    {numberFormatterTenths.format(prototype.density)} dwelling
                    units per acre
                  </td>
                </tr>
                <tr>
                  <td>
                    Floor Area Ratio
                    <br />
                    <small>
                      The ratio of the gross building area to the site size.
                    </small>
                  </td>
                  <td>
                    {numberFormatterHundredths.format(prototype.floorAreaRatio)}
                  </td>
                </tr>
                <tr>
                  <td>
                    Garage Stalls
                    <br />
                    <small>The total number of garage stalls.</small>
                  </td>
                  <td>{prototype.availableGarageParking}</td>
                </tr>
                <tr>
                  <td>
                    Surface Parking Stalls / Driveway Spaces
                    <br />
                    <small>The total number of surface parking stalls.</small>
                  </td>
                  <td>
                    {numberFormatter.format(prototype.surfaceParkingStalls)}
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    Physical Feasability
                    <br />
                    <small>
                      A site is considered physically feasible if some portion
                      of it remains for landscaping.
                    </small>
                  </td>
                  <td>
                    {prototype.landscapeAreaCheck
                      ? "Site size is adequate"
                      : "Site size is too small"}
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="card text-white bg-secondary mb-3">
          <h5 className="card-header">Financial Results</h5>
          <div className="card-body">
            <Table className="text-white">
              <tbody>
                <tr>
                  <td>
                    {prototype.type === "rent" ? "Renter" : "Owner"} MFI Level
                    <br />
                    <small>
                      The percentage of Median Family Income at which units are
                      affordable.
                    </small>
                  </td>
                  <td>
                    {percentFormatter.format(
                      prototype.type === "rent"
                        ? prototype.renterMFILevel
                        : prototype.ownerMFILevel
                    )}
                    <br />
                    <small>
                      MFI of {dollarFormatter.format(prototype.MFI)} for{" "}
                      {prototype.county}
                    </small>
                  </td>
                </tr>
                <tr>
                  <td>
                    Maximum Site Cost
                    <br />
                    <small>
                      The residual land value: the maximum amount that may be
                      spent on land while maintaining financial feasability.
                      Compare to estimated market value of site. If negative,
                      development is not feasible without subsidy, even if land
                      is free.
                    </small>
                  </td>
                  <td>
                    {dollarFormatter.format(prototype.totalResidualLandValue)}
                    <br />
                    {dollarFormatter.format(prototype.residualLandValuePsf)} per
                    square foot
                  </td>
                </tr>
                <tr>
                  <td>
                    Total Local Fees
                    <br />
                    <small>The sum of all local impact and permit fees.</small>
                  </td>
                  <td>
                    {dollarFormatter.format(
                      prototype.totalLocalImpactFees + prototype.localPermitFees
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Col>
    )
  }
  return ""
}

export default PrototypeResultsCol
