export const numberFormatter = new Intl.NumberFormat("en-US", {
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const numberFormatterTenths = new Intl.NumberFormat("en-US", {
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

export const numberFormatterHundredths = new Intl.NumberFormat("en-US", {
  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const dollarFormatterCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export function median(numbers) {
  const sorted = numbers.slice().sort((a, b) => a - b)
  const middle = Math.floor(sorted.length / 2)

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2
  }

  return sorted[middle]
}
