import React, { useEffect, useState } from "react"

import { Col, Form, Row, Table } from "react-bootstrap"
import RangeSlider from "react-bootstrap-range-slider"

import "./index.css"

import { Prototype, prototypes } from "./prototype"
import {
  dollarFormatter,
  numberFormatter,
  numberFormatterTenths,
  median,
} from "../utils"

const MFIbyCounty = {
  "Alameda County": 125600,
  "Contra Costa County": 125600,
  "Marin County": 149600,
  "Napa County": 101500,
  "San Francisco County": 149600,
  "San Mateo County": 149600,
  "Santa Clara County": 151300,
  "Solano County": 99300,
  "Sonoma County": 103300,
}

const QuestionRangeSliderRow = (props) => {
  return (
    <Form.Group controlId={props.controlId} as={Row} className="mb-3">
      <Form.Label column sm={4} aria-hidden={true}>
        {props.label}
      </Form.Label>
      <Col sm={8}>
        <Row>
          {props.textAboveSlider}
          <Col xs={8} className="d-print-none">
            <RangeSlider
              id={props.controlId}
              value={props.value}
              min={props.min}
              max={props.max}
              step={props.step}
              onChange={props.handleChange}
              tooltipLabel={(currentValue) =>
                props.formatter.format(currentValue)
              }
              tooltipProps={{ "aria-hidden": true }}
              aria-label={props.stringLabel || props.label}
              aria-describedby={props.controlId + "-help-block"}
            />
            <p
              className="text-muted text-nowrap float-start"
              aria-hidden={true}
            >
              {props.formatter.format(props.min)}
            </p>
            <p className="text-muted text-nowrap float-end" aria-hidden={true}>
              {props.formatter.format(props.max)}
            </p>
          </Col>
          <Col xs={4} className="text-nowrap" aria-hidden={true}>
            {props.formatter.format(props.value)}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Text
              id={props.controlId + "-help-block"}
              muted
              // aria-label={`Help text for ${props.stringLabel || props.label}`}
              aria-hidden={true}
            >
              {props.helpText}
              <span className="d-none">
                {" "}
                {`Minimum value allowed is ${props.min} and maximum value allowed is ${props.max}`}
              </span>
            </Form.Text>
          </Col>
        </Row>
      </Col>
    </Form.Group>
  )
}

const getPrototype = (prototypeName, answers, county) => {
  const prototypeVariables = { ...prototypes[prototypeName] }
  prototypeVariables.shortName = prototypeName
  if (prototypeVariables.type === "rent") {
    prototypeVariables.rentPsf =
      answers.price / prototypeVariables.rentUnitSizeSf
  } else {
    prototypeVariables.salesPricePsf =
      answers.price / prototypeVariables.salesUnitSizeSf
  }
  prototypeVariables.siteSize = answers.siteSize
  prototypeVariables.developmentDuration = answers.developmentDuration
  prototypeVariables.localImpactFees = answers.localImpactFees
  prototypeVariables.localPermitFees = answers.localPermitFees
  prototypeVariables.parkingRatio = answers.parkingRatio
  prototypeVariables.county = county
  prototypeVariables.MFI = MFIbyCounty[county]
  prototypeVariables.existingProperty = answers.existingProperty
  if (["townhouse", "townhouseLarge", "multiplex"].includes(prototypeName)) {
    prototypeVariables.numberOfUnits = answers.numberOfUnits
  }
  return new Prototype(prototypeVariables)
}

const ProForma = (props) => {
  const {
    county,
    jurisdiction,
    jurisdictionSurface,
    prototypeName,
    handlePrototypeResults,
    prototype,
  } = props
  const [answers, setAnswers] = useState({
    numberOfUnits: (prototype && prototype.numberOfUnitsMin) || 2,
    price: null,
    siteSize: 6000,
    parkingRatio: 2.0,
    developmentDuration: 18,
    localImpactFees: 25000,
    localPermitFees: 8000,
    existingProperty: "yes",
  })
  // const [showFirstTime, setShowFirstTime] = useState(true)
  const [surfaceValues, setSurfaceValues] = useState({
    min: null,
    max: null,
    median: null,
    type: null,
  })

  useEffect(() => {
    const prototype = getPrototype(prototypeName, answers, county)
    // Fix former answers that are out of new range
    if (answers.parkingRatio > prototype.parkingRatioMax) {
      setAnswers((answers) => ({
        ...answers,
        parkingRatio: prototype.parkingRatioMax,
      }))
    }
    if (answers.parkingRatio < prototype.parkingRatioMin) {
      setAnswers((answers) => ({
        ...answers,
        parkingRatio: prototype.parkingRatioMin,
      }))
    }
    if (answers.numberOfUnits > prototype.numberOfUnitsMax) {
      setAnswers((answers) => ({
        ...answers,
        numberOfUnits: prototype.numberOfUnitsMax,
      }))
    }
    if (answers.numberOfUnits < prototype.numberOfUnitsMin) {
      setAnswers((answers) => ({
        ...answers,
        numberOfUnits: prototype.numberOfUnitsMin,
      }))
    }
    if (answers.siteSize > prototype.siteSizeMax) {
      setAnswers((answers) => ({ ...answers, siteSize: prototype.siteSizeMax }))
    }
    if (answers.siteSize < prototype.siteSizeMin) {
      setAnswers((answers) => ({ ...answers, siteSize: prototype.siteSizeMin }))
    }
    handlePrototypeResults(prototype)
  }, [answers, county, handlePrototypeResults, prototypeName])

  // // Run when answers change
  // useEffect(() => {
  //   console.log(answers)
  // }, [answers])

  useEffect(() => {
    if (jurisdictionSurface) {
      const prototypeVariables = { ...prototypes[prototypeName] }
      // console.log(
      //   `${jurisdiction} overlaps with all or part of ${jurisdictionSurface.features.length} blockgroups.`
      // )
      const values = jurisdictionSurface.features.map(
        (item) => item.properties[prototypeVariables.variable]
      )
      const step = prototypeVariables.type === "rent" ? 25 : 10000
      // console.log(values)
      const minValue = Math.round(Math.min(...values) / step) * step
      const maxValue = Math.round(Math.max(...values) / step) * step
      const medianValue = Math.round(median(values) / step) * step
      // console.log(`Min: ${minValue}; Max: ${maxValue}; Median: ${medianValue}`)
      setSurfaceValues({
        min: minValue,
        max: maxValue,
        median: medianValue,
        step: step,
        type: prototypeVariables.type,
      })

      setAnswers((answers) => ({
        ...answers,
        price: medianValue,
        parkingRatio: prototypeVariables.parkingRatioDefault,
      }))
    }
  }, [prototypeName, county, jurisdiction, jurisdictionSurface])

  const handleChange = (e) => {
    const target = e.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const id = target.type === "radio" ? target.name : target.id
    setAnswers((answers) => ({ ...answers, [id]: value }))
  }

  if (jurisdictionSurface) {
    const priceLabel = (
      <span>
        Estimated {surfaceValues.type === "rent" ? "Rent" : "Sale Price"}
        <br />
        <small className="text-muted">
          {prototypes[prototypeName].variableDesc}
        </small>
      </span>
    )
    const priceStringLabel = `Estimated ${
      surfaceValues.type === "rent" ? "Rent" : "Sale Price"
    } for a ${prototypes[prototypeName].variableDesc}`
    const priceHelpTextFiller =
      surfaceValues.type === "rent" ? "Monthly rent" : "Sale price"
    const priceHelpText = `${priceHelpTextFiller} for this unit.`
    const priceTextAboveSlider = `The estimated market ${
      surfaceValues.type === "rent" ? "rent" : "sale price"
    } for this prototype in ${jurisdiction.replace(
      /(City|Town) of /g,
      ""
    )} is ${dollarFormatter.format(
      surfaceValues.min
    )} to ${dollarFormatter.format(surfaceValues.max)}.`

    return (
      <Col sm="12" md="6">
        {answers.price && (
          <div>
            <div className="card mb-3">
              <h5 className="card-header">Physical Form</h5>
              <div className="card-body">
                <p>
                  Set your assumptions for{" "}
                  {prototype.numberOfUnitsMin && "unit count,"} required parking
                  ratio{prototype.numberOfUnitsMin && ","} and minimum required
                  site size. Check the physical form results to make sure that
                  everything fits and compare the form results to your zoning
                  regulations.
                </p>

                {prototype.numberOfUnitsMin && (
                  <QuestionRangeSliderRow
                    controlId="numberOfUnits"
                    label="Number of Units"
                    value={answers.numberOfUnits}
                    handleChange={handleChange}
                    min={prototype.numberOfUnitsMin}
                    max={prototype.numberOfUnitsMax}
                    step={prototype.numberOfUnitsStep}
                    formatter={numberFormatter}
                    helpText="The number of units to model for the prototype."
                  />
                )}
                <QuestionRangeSliderRow
                  controlId="parkingRatio"
                  label="Parking Ratio"
                  value={answers.parkingRatio}
                  handleChange={handleChange}
                  min={prototype.parkingRatioMin}
                  max={prototype.parkingRatioMax}
                  step={prototype.parkingRatioStep}
                  formatter={numberFormatterTenths}
                  helpText="The number of off-street parking spaces required per unit."
                />
                <QuestionRangeSliderRow
                  controlId="siteSize"
                  label="Required Total Site Size"
                  value={answers.siteSize}
                  handleChange={handleChange}
                  min={prototype.siteSizeMin}
                  max={prototype.siteSizeMax}
                  step={prototype.siteSizeStep}
                  formatter={numberFormatter}
                  helpText="The site size required for this prototype, in square feet, given density, lot coverage, and other development standards. Check physical feasibility in results."
                />
              </div>
            </div>
            <div className="card mb-3">
              <h5 className="card-header">Financial Feasibility</h5>
              <div className="card-body">
                <p>
                  Adjust the financial inputs to see how they affect financial
                  feasibility. Compare the maximum site cost to property values
                  in your area to see if the development of this prototype may
                  be feasible.
                </p>
                <p>{priceTextAboveSlider}</p>
                <QuestionRangeSliderRow
                  controlId="price"
                  label={priceLabel}
                  stringLabel={priceStringLabel}
                  value={answers.price}
                  handleChange={handleChange}
                  min={surfaceValues.type === "rent" ? 500 : 500000}
                  max={surfaceValues.type === "rent" ? 6000 : 5000000}
                  step={surfaceValues.type === "rent" ? 50 : 50000}
                  formatter={dollarFormatter}
                  helpText={priceHelpText}
                />
                <QuestionRangeSliderRow
                  controlId="localImpactFees"
                  label="Local Impact Fees (per unit)"
                  value={answers.localImpactFees}
                  handleChange={handleChange}
                  min={0}
                  max={100000}
                  step={1000}
                  formatter={dollarFormatter}
                  helpText="The amount of local impact fees charged per unit."
                />
                <QuestionRangeSliderRow
                  controlId="localPermitFees"
                  label="Local Permit Fees"
                  value={answers.localPermitFees}
                  handleChange={handleChange}
                  min={0}
                  max={100000}
                  step={1000}
                  formatter={dollarFormatter}
                  helpText="The amount of local permit fees charged for the entire development."
                />
                <QuestionRangeSliderRow
                  controlId="developmentDuration"
                  label="Development Duration"
                  value={answers.developmentDuration}
                  handleChange={handleChange}
                  min={9}
                  max={36}
                  step={1}
                  formatter={numberFormatter}
                  helpText="The length of time that permitting and construction will take, in months."
                />
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={4} aria-hidden={true}>
                    Existing Structure
                  </Form.Label>
                  <Col sm={8}>
                    <Row>
                      <Col
                        role="radiogroup"
                        aria-label="Existing Structure"
                        aria-describedby="existingProperty-help-block"
                      >
                        <Form.Check
                          inline
                          type="radio"
                          name="existingProperty"
                          value="yes"
                          id="existingProperty-2"
                          label="Yes"
                          checked={answers.existingProperty === "yes"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          name="existingProperty"
                          value="no"
                          id="existingProperty-1"
                          label="No"
                          checked={answers.existingProperty === "no"}
                          onChange={handleChange}
                          tabindex="-1"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Text
                          id="existingProperty-help-block"
                          muted
                          aria-hidden={true}
                        >
                          Is there an existing structure which will require
                          demolition?
                        </Form.Text>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </div>
            </div>
          </div>
        )}
        <div className="card mb-3">
          <h5 className="card-header">Prototype Definitions</h5>
          <div className="card-body">
            {answers.price && (
              <Table>
                <tbody>
                  <tr>
                    <td>Housing Type</td>
                    <td>{prototype.name}</td>
                  </tr>
                  <tr>
                    <td>Number of Units</td>
                    <td>{prototype.totalUnits}</td>
                  </tr>
                  <tr>
                    <td>Average Unit Size</td>
                    <td>
                      {surfaceValues.type === "rent"
                        ? numberFormatter.format(prototype.rentUnitSizeSf)
                        : numberFormatter.format(
                            prototype.salesUnitSizeSf
                          )}{" "}
                      square feet
                    </td>
                  </tr>
                  <tr>
                    <td>Height (in floors)</td>
                    <td>{prototype.heightFl}</td>
                  </tr>
                  <tr>
                    <td>Utility Hookups</td>
                    <td>{prototype.utilityHookups}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </Col>
    )
  }
  return ""
}

export default ProForma
