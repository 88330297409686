import React from "react"
import { Col, Form, Row } from "react-bootstrap"

import { jurisdictionsByCounty } from "../geographies"

class CountySelect extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.handleChange({ county: e.target.value })
  }

  render() {
    const counties = Object.keys(jurisdictionsByCounty)
    let value = this.props.county || "Choose a county"

    return (
      <Form.Group as={Row} className="mb-3" controlId="county">
        <Form.Label column sm={2} aria-hidden={true}>
          County:
        </Form.Label>
        <Col sm={10}>
          <Form.Select onChange={this.handleChange} value={value}>
            <option disabled>Choose a county</option>
            {counties.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
    )
  }
}

class JurisdictionSelect extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.handleChange({
      jurisdiction: e.target.value,
    })
  }

  render() {
    const county = this.props.county
    let value = this.props.jurisdiction || "Choose a jurisdiction"
    let jurisdictions = []
    let firstOption = "First choose a county"
    if (county in jurisdictionsByCounty) {
      jurisdictions = jurisdictionsByCounty[county]
      firstOption = "Choose a jurisdiction"
    } else {
      value = firstOption
    }

    return (
      <Form.Group as={Row} className="mb-3" controlId="jurisdiction">
        <Form.Label column sm={2} aria-hidden={true}>
          Jurisdiction:
        </Form.Label>
        <Col sm={10}>
          <Form.Select onChange={this.handleChange} value={value}>
            <option disabled>{firstOption}</option>
            {jurisdictions.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Form.Group>
    )
  }
}

class GeographySelection extends React.Component {
  render() {
    return (
      <div className="geography-selection">
        <h2>Search Tool</h2>
        <p>
          Using the drop down menu, first select a county and then select a city
          or town from the jurisdiction drop-down menu.
        </p>
        <CountySelect
          county={this.props.county}
          handleChange={this.props.handleChange}
        />
        <JurisdictionSelect
          county={this.props.county}
          jurisdiction={this.props.jurisdiction}
          handleChange={this.props.handleChange}
        />
      </div>
    )
  }
}

export default GeographySelection
