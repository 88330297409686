export const geographies = {
  "Alameda County": [
    { name: "City of Alameda" },
    { name: "City of Albany" },
    { name: "City of Berkeley" },
    { name: "City of Dublin" },
    { name: "City of Emeryville" },
    { name: "City of Fremont" },
    { name: "City of Hayward" },
    { name: "City of Livermore" },
    { name: "City of Newark" },
    { name: "City of Oakland" },
    { name: "City of Piedmont" },
    { name: "City of Pleasanton" },
    { name: "City of San Leandro" },
    { name: "City of Union City" },
  ],
  "Contra Costa County": [
    { name: "City of Antioch" },
    { name: "City of Brentwood" },
    { name: "City of Clayton" },
    { name: "City of Concord" },
    { name: "Town of Danville" },
    { name: "City of El Cerrito" },
    { name: "City of Hercules" },
    { name: "City of Lafayette" },
    { name: "City of Martinez" },
    { name: "Town of Moraga" },
    { name: "City of Oakley" },
    { name: "City of Orinda" },
    { name: "City of Pinole" },
    { name: "City of Pittsburg" },
    { name: "City of Pleasant Hill" },
    { name: "City of Richmond" },
    { name: "City of San Pablo" },
    { name: "City of San Ramon" },
    { name: "City of Walnut Creek" },
  ],
  "Marin County": [
    { name: "City of Belvedere" },
    { name: "Town of Fairfax" },
    { name: "City of Larkspur" },
    { name: "City of Mill Valley" },
    { name: "City of Novato" },
    { name: "Town of Ross" },
    { name: "Town of San Anselmo" },
    { name: "City of San Rafael" },
    { name: "City of Sausalito" },
    { name: "Town of Tiburon" },
  ],
  "Napa County": [
    { name: "City of American Canyon" },
    { name: "City of Calistoga" },
    { name: "City of Napa" },
    { name: "City of St. Helena" },
    { name: "Town of Yountville" },
  ],
  "San Francisco County": [{ name: "City of San Francisco" }],
  "San Mateo County": [
    { name: "Town of Atherton" },
    { name: "City of Belmont" },
    { name: "City of Brisbane" },
    { name: "City of Burlingame" },
    { name: "Town of Colma" },
    { name: "City of Daly City" },
    { name: "City of East Palo Alto" },
    { name: "City of Foster City" },
    { name: "City of Half Moon Bay" },
    { name: "Town of Hillsborough" },
    { name: "City of Menlo Park" },
    { name: "City of Millbrae" },
    { name: "City of Pacifica" },
    { name: "Town of Portola Valley" },
    { name: "City of Redwood City" },
    { name: "City of San Bruno" },
    { name: "City of San Carlos" },
    { name: "City of San Mateo" },
    { name: "City of South San Francisco" },
    { name: "Town of Woodside" },
  ],
  "Santa Clara County": [
    { name: "City of Campbell" },
    { name: "City of Cupertino" },
    { name: "City of Gilroy" },
    { name: "City of Los Altos" },
    { name: "Town of Los Altos Hills" },
    { name: "Town of Los Gatos" },
    { name: "City of Milpitas" },
    { name: "City of Monte Sereno" },
    { name: "City of Morgan Hill" },
    { name: "City of Mountain View" },
    { name: "City of Palo Alto" },
    { name: "City of San Jose" },
    { name: "City of Santa Clara" },
    { name: "City of Saratoga" },
    { name: "City of Sunnyvale " },
  ],
  "Solano County": [
    { name: "City of Benicia" },
    { name: "City of Dixon" },
    { name: "City of Fairfield" },
    { name: "City of Rio Vista" },
    { name: "City of Suisun City" },
    { name: "City of Vacaville" },
  ],
  "Sonoma County": [
    { name: "City of Cloverdale" },
    { name: "City of Cotati" },
    { name: "City of Healdsburg" },
    { name: "City of Petaluma" },
    { name: "City of Rohnert Park" },
    { name: "City of Santa Rosa" },
    { name: "City of Sebastopol" },
    { name: "City of Sonoma" },
    { name: "Town of Windsor" },
  ],
}

export const jurisdictionsByCounty = Object.fromEntries(
  Object.entries(geographies).map(([k, v]) => [k, v.map((item) => item.name)])
)
