import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import "./App.css"
import "bootstrap-print-css/css/bootstrap-print.css"

import GeographySelection from "./GeographySelection"
import PrototypeSelection from "./PrototypeSelection"
import SurfaceMap from "./SurfaceMap"

import ProForma from "./ProForma"
import PrototypeResultsCol from "./ProFormaResults"

import { jurisdictionsByCounty } from "./geographies"

// TODO: Highlight selected jurisdiction / county on map
// BUG: Select another county, then a prototype, then pick san francisco and it doesn't unselect the prototype

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      county: "",
      jurisdiction: "",
      jurisdictionSurface: null,
      prototypeName: "",
      answers: {},
      prototype: null,
    }
    this.handleGeographyChange = this.handleGeographyChange.bind(this)
    this.handlePrototypeSelection = this.handlePrototypeSelection.bind(this)
    this.handlePrototypeResults = this.handlePrototypeResults.bind(this)
    this.handleSurfaceSelection = this.handleSurfaceSelection.bind(this)
  }

  handleGeographyChange(newGeography) {
    this.setState(function (state, props) {
      if (newGeography.county && newGeography.county !== state.county) {
        newGeography.jurisdiction = ""
        newGeography.jurisdictionSurface = null
        newGeography.prototypeName = ""
        newGeography.answers = {}
        const newJurisdictions = jurisdictionsByCounty[newGeography.county]
        // San Francisco county only has one jurisdiction in it
        if (newJurisdictions.length === 1) {
          newGeography.jurisdiction = newJurisdictions[0]
        }
      }
      return newGeography
    })
  }

  handlePrototypeSelection(prototypeSelection) {
    this.setState(prototypeSelection)
  }
  handlePrototypeResults(prototype) {
    this.setState({
      prototype,
    })
  }

  handleSurfaceSelection(jurisdictionSurface) {
    this.setState({
      jurisdictionSurface,
    })
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <p>
              Use this tool to identify what the middle housing development
              market potential is in jurisdictions through the San Francisco Bay
              Area.
            </p>
            <p>
              If you need assistance, please email{" "}
              <a href="cmcdevitt@bayareametro.gov">
                cmcdevitt@bayareametro.gov
              </a>
              .
            </p>
          </Col>
        </Row>
        <Row className="tool-row">
          <Col className="left-col" lg={6}>
            <GeographySelection
              county={this.state.county}
              jurisdiction={this.state.jurisdiction}
              handleChange={this.handleGeographyChange}
            />
            <PrototypeSelection
              jurisdiction={this.state.jurisdiction}
              handlePrototypeSelection={this.handlePrototypeSelection}
            />
            {this.state.prototypeName && (
              <p className="d-print-none" aria-hidden={true}>
                <a href="#pro-forma">Scroll down to pro-forma</a>
              </p>
            )}
          </Col>
          <Col className="right-col d-print-none" lg={6}>
            <SurfaceMap
              county={this.state.county}
              jurisdiction={this.state.jurisdiction}
              prototypeName={this.state.prototypeName}
              handleSurfaceSelection={this.handleSurfaceSelection}
            />
          </Col>
        </Row>
        {this.state.prototypeName && (
          <span id="pro-forma">
            <Row className="pro-forma-title top-border">
              <h2>
                Feasibility Estimator
                {this.state.prototype && (
                  <span> - {this.state.prototype.name}</span>
                )}
              </h2>
            </Row>
            <Row className="pro-forma">
              <ProForma
                county={this.state.county}
                jurisdiction={this.state.jurisdiction}
                jurisdictionSurface={this.state.jurisdictionSurface}
                prototypeName={this.state.prototypeName}
                handlePrototypeResults={this.handlePrototypeResults}
                prototype={this.state.prototype}
              />
              <PrototypeResultsCol prototype={this.state.prototype} />
            </Row>
          </span>
        )}
        <Row>
          <Col className="footer top-border text-end">
            <strong>Tool created by ECONorthwest</strong>
            <br />
            www.econw.com
          </Col>
        </Row>
      </Container>
    )
  }
}

export default App
