import React, { useEffect, useState } from "react"

import { Form } from "react-bootstrap"

import "./index.css"

const Checkbox = (props) => {
  const label =
    props.name === "townhouse"
      ? "Small Townhouses"
      : props.name === "townhouseLarge"
      ? "Large Townhouses"
      : props.name.charAt(0).toUpperCase() + props.name.slice(1)
  return (
    <Form.Check
      inline
      type="radio"
      name="prototype"
      id={"prototype-" + props.number}
      value={props.name}
      aria-label={label}
      label={" "}
      className={props.name}
      onChange={props.handleChange}
    />
  )
}

const Checkboxes = (props) => {
  return props.prototypes.map((value, index) => (
    <Checkbox
      name={value}
      key={index}
      number={index}
      handleChange={props.handleChange}
    />
  ))
}

const PrototypeSelection = (props) => {
  const [answers, setAnswers] = useState({
    prototype: null,
  })

  const { jurisdiction, handlePrototypeSelection } = props

  // Runs when prototype changes
  useEffect(() => {
    answers.prototype &&
      handlePrototypeSelection({
        prototypeName: answers.prototype,
      })
  }, [handlePrototypeSelection, answers.prototype])

  const handleChange = (e) => {
    const target = e.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const id = target.type === "radio" ? target.name : target.id
    setAnswers((answers) => ({ ...answers, [id]: value }))
  }

  if (jurisdiction) {
    return (
      <div className="prototype-selection d-print-none">
        <Form.Group>
          <h3>Choose a prototype:</h3>
          <div className="prototype-radios">
            <Checkboxes
              prototypes={[
                "townhouse",
                "townhouseLarge",
                "duplex",
                "sixplex",
                "fourplex",
                "multiplex",
              ]}
              handleChange={handleChange}
            />
          </div>
        </Form.Group>
      </div>
    )
  }
  return ""
}

export default PrototypeSelection
