export class Prototype {
  constructor(properties) {
    // Prototype Definitions
    this.shortName = properties.shortName
    this.name = properties.name
    this.type = properties.type
    this.salesUnits = properties.salesUnits || 0
    this.rentUnits = properties.rentUnits || 0
    if (properties.numberOfUnits) {
      if (this.type === "sale") {
        this.salesUnits = parseInt(properties.numberOfUnits)
      } else {
        this.rentUnits = parseInt(properties.numberOfUnits)
      }
    }
    // this.ADUUnits = properties.ADUUnits || 0
    if (this.shortName === "multiplex") {
      this.heightFl = this.rentUnits === 8 ? 2 : 3
      this.heightFt = 5 + this.heightFl * 10
    } else {
      this.heightFl = properties.heightFl
      this.heightFt = properties.heightFt
    }
    if (properties.numberOfUnits && this.type === "sale") {
      this.garageStalls = this.salesUnits * properties.garageStalls
    } else {
      this.garageStalls = properties.garageStalls || 0
    }

    this.salesUnitSizeSf = properties.salesUnitSizeSf || 0
    this.rentUnitSizeSf = properties.rentUnitSizeSf || 0
    this.grossToNetRatio = properties.grossToNetRatio || 1
    this.costPSF = properties.costPSF
    this.mfiAdjustment = properties.mfiAdjustment

    this.utilityHookups = properties.utilityHookups

    this.parkingRatioMin = properties.parkingRatioMin
    this.parkingRatioMax = properties.parkingRatioMax
    this.parkingRatioStep = properties.parkingRatioStep
    if (["townhouse", "townhouseLarge"].includes(this.shortName)) {
      this.siteSizeMin = properties.siteSizeMin * this.salesUnits
      this.siteSizeMax = properties.siteSizeMax * this.salesUnits
    } else {
      this.siteSizeMin = properties.siteSizeMin
      this.siteSizeMax = properties.siteSizeMax
    }
    this.siteSizeStep = properties.siteSizeStep
    this.numberOfUnitsMin = properties.numberOfUnitsMin
    this.numberOfUnitsMax = properties.numberOfUnitsMax
    this.numberOfUnitsStep = properties.numberOfUnitsStep

    this.county = properties.county

    // Inputs / Assumptions
    ///////////////////////

    // Physical Assumptions
    this.garageSize = 200
    this.siteSize = parseInt(properties.siteSize) // user controlled
    this.surfaceParkingSize = 350
    this.drivewaySize = 120
    this.garageDrivewaySize = 100

    // Policy Assumptions
    this.parkingRatio = parseFloat(properties.parkingRatio) // user controlled
    this.maxBuildingCoverage = 0.4 // possibly user controlled
    this.maxHeight = 30 // possibly user controlled
    this.MFI = properties.MFI
    this.pctIncomeAffordable = 0.3
    this.utilitiesAllowance = 300
    this.renterIncomeMultiplier = 0.9
    this.downpayment = 0.2
    this.mortgageInterestRate = 0.035
    this.mortgageDuration = 30
    this.homeownersInsuranceRate = 0.002
    this.propertyTaxRate = 0.01
    this.HOA = 200
    this.homeownerExpenses = 60

    // Financial Assumptions - Hard Cost
    this.ADUAreaCostPsf = 220
    this.garageCostPerStall = 15000
    this.surfaceParkingCostPsf = 25
    this.hardscapeCostPsf = 25
    this.landscapeCostPsf = 4
    this.sitePrepCostPsf = 20
    this.developedProperty = properties.existingProperty === "yes" // user controlled
    this.demolition = 22000

    // Financial Assumptions - Soft Cost
    this.localImpactFees = parseInt(properties.localImpactFees) // user controlled
    this.localPermitFees = parseInt(properties.localPermitFees) // user controlled
    this.landDivisionCostIfNeeded = 5000
    this.developmentDuration = parseInt(properties.developmentDuration) // user controlled
    this.constructionInterestRate = 0.09
    this.fixedCarryCost = 8000 / 12
    this.otherSoftCostRate = 0.14
    this.contingencyFeeRate = 0.03
    this.developerFeeRate = 0.03

    // Revenue (from prediction model)
    this.salesPricePsf = properties.salesPricePsf || 0
    this.rentPsf = properties.rentPsf || 0
    this.salesCommissionRate = 0.03
    this.rentEscalation = 1.03

    // Operating Expense (rental only)
    this.vacancyRate = 0.05
    this.operatingExpenseRate = 0.27

    // Return Metrics
    this.loanToCost = 0.7
    this.targetDSCR = 1.2 // Debt Service Cost Ratio (?)
    this.interestRate = 0.055
    this.loanDuration = 30
    this.returnOnCost = 0.05 // ROC in spreadsheet
    this.salesProfitMarginRate = 0.15
  }

  get totalUnits() {
    return this.salesUnits + this.rentUnits
  }

  get totalUnitSizeSf() {
    return (
      this.salesUnits * this.salesUnitSizeSf +
      this.rentUnits * this.rentUnitSizeSf
    )
  }

  // Pro Forma
  // 1. Form Calculations
  get parkingNeed() {
    return this.parkingRatio * this.totalUnits
  }

  get availableGarageParking() {
    return this.garageStalls
  }

  get surfaceParkingStalls() {
    return Math.max(0, this.parkingNeed - this.availableGarageParking)
  }

  get residentialGrossBuildingArea() {
    return this.totalUnitSizeSf / this.grossToNetRatio
  }

  get garageArea() {
    return this.availableGarageParking * this.garageSize
  }

  get totalBuildingArea() {
    return this.residentialGrossBuildingArea + this.garageArea
  }

  get buildingFloorplate() {
    return this.totalBuildingArea / this.heightFl
  }

  get surfaceParkingArea() {
    return this.surfaceParkingStalls * this.surfaceParkingSize
  }

  get garageDrivewayArea() {
    return this.availableGarageParking * this.garageDrivewaySize
  }

  get drivewayArea() {
    if (this.parkingNeed > 0) {
      return this.drivewaySize
    } else {
      return 0
    }
  }

  get landscapeArea() {
    return (
      this.siteSize -
      this.buildingFloorplate -
      this.surfaceParkingArea -
      this.garageDrivewayArea -
      this.drivewayArea
    )
  }

  get density() {
    return this.totalUnits / (this.siteSize / 43560)
  }

  get floorAreaRatio() {
    return this.residentialGrossBuildingArea / this.siteSize
  }

  //   Checks
  get buildingCoverage() {
    return this.buildingFloorplate / this.siteSize
  }
  get imperviousCoverage() {
    return (
      (this.surfaceParkingArea + this.garageDrivewayArea + this.drivewayArea) /
      this.siteSize
    )
  }
  get landscapeCoverage() {
    return this.landscapeArea / this.siteSize
  }
  get landscapeAreaCheck() {
    return this.landscapeArea >= 0
  }
  get maxBuildingCoverageCheck() {
    return this.buildingCoverage <= this.maxBuildingCoverage
  }
  get maxHeightCheck() {
    return this.heightFt <= this.maxHeight
  }
  get formCheck() {
    return (
      this.landscapeAreaCheck &&
      this.maxBuildingCoverageCheck &&
      this.maxHeightCheck
    )
  }

  // 2. Financial Calculations - Costs
  // Hard Costs
  get totalSalesArea() {
    return (this.salesUnits * this.salesUnitSizeSf) / this.grossToNetRatio
  }

  get totalRentArea() {
    return (this.rentUnits * this.rentUnitSizeSf) / this.grossToNetRatio
  }

  get salesShare() {
    return this.totalSalesArea / this.residentialGrossBuildingArea
  }

  get rentShare() {
    return this.totalRentArea / this.residentialGrossBuildingArea
  }

  get residentialCost() {
    return (this.totalSalesArea + this.totalRentArea) * this.costPSF
  }

  get garageCost() {
    return this.garageStalls * this.garageCostPerStall
  }

  get surfaceParkingCost() {
    return this.surfaceParkingArea * this.surfaceParkingCostPsf
  }

  get hardscapeCost() {
    return (this.garageDrivewayArea + this.drivewayArea) * this.hardscapeCostPsf
  }

  get landscapeCost() {
    return this.landscapeArea * this.landscapeCostPsf
  }

  get sitePrepCost() {
    return this.siteSize * this.sitePrepCostPsf
  }

  get demoCost() {
    return this.developedProperty * this.demolition
  }

  get totalHardCosts() {
    return [
      this.residentialCost,
      this.garageCost,
      this.surfaceParkingCost,
      this.hardscapeCost,
      this.landscapeCost,
      this.sitePrepCost,
      this.demoCost,
    ].reduce((sum, y) => sum + y)
  }

  // Checks
  get hardCostPerUnitCheck() {
    return this.totalHardCosts / this.totalUnits
  }
  get hardCostPsfCheck() {
    return this.totalHardCosts / this.residentialGrossBuildingArea
  }

  // Soft Costs
  get otherSoftCosts() {
    return this.totalHardCosts * this.otherSoftCostRate
  }

  get totalLocalImpactFees() {
    return this.localImpactFees * this.totalUnits
  }

  get landDivisionCost() {
    return this.salesUnits > 1 ? this.landDivisionCostIfNeeded : 0
  }

  get constructionLoanAmount() {
    return (
      (this.totalHardCosts +
        this.totalLocalImpactFees +
        this.localPermitFees +
        this.landDivisionCost +
        this.otherSoftCosts) /
      2
    )
  }

  get monthlyConstructionLoanInterestPayment() {
    return (this.constructionLoanAmount * this.constructionInterestRate) / 12
  }

  get carryingCosts() {
    return (
      (this.monthlyConstructionLoanInterestPayment + this.fixedCarryCost) *
      this.developmentDuration
    )
  }
  get softCostsSubtotal() {
    return (
      this.totalLocalImpactFees +
      this.localPermitFees +
      this.landDivisionCost +
      this.otherSoftCosts +
      this.carryingCosts
    )
  }

  get contingencyFee() {
    return (
      (this.totalHardCosts + this.softCostsSubtotal) * this.contingencyFeeRate
    )
  }

  get developerFee() {
    return (
      (this.totalHardCosts + this.softCostsSubtotal + this.contingencyFee) *
      this.developerFeeRate
    )
  }

  get totalSoftCosts() {
    return this.softCostsSubtotal + this.contingencyFee + this.developerFee
  }

  get totalDevelopmentCosts() {
    return this.totalSoftCosts + this.totalHardCosts
  }

  // Checks
  get totalSoftCostRateCheck() {
    return this.totalSoftCosts / this.totalHardCosts
  }

  get developmentCostPerUnit() {
    return this.totalDevelopmentCosts / this.totalUnits
  }

  get developmentCostPsf() {
    return this.totalDevelopmentCosts / this.residentialGrossBuildingArea
  }

  // 3a. Financial Calculations - Sale Value
  get salesPricePerUnit() {
    return this.salesPricePsf * this.salesUnitSizeSf
  }

  get grossSalesRevenue() {
    return this.salesPricePerUnit * this.salesUnits
  }

  get salesCommission() {
    return this.grossSalesRevenue * this.salesCommissionRate
  }

  get salesProfitMargin() {
    return this.grossSalesRevenue > 0
      ? this.totalDevelopmentCosts * this.salesProfitMarginRate
      : 0
  }

  get netSalesRevenue() {
    return (
      this.grossSalesRevenue - this.salesCommission - this.salesProfitMargin
    )
  }

  get salesResidualLandValue() {
    return this.netSalesRevenue - this.totalDevelopmentCosts * this.salesShare
  }

  // 3b. Financial Calculations - Rent Value
  // Revenue

  get monthlyRentPerUnit() {
    return this.rentPsf * this.rentUnitSizeSf
  }

  get monthlyRentalIncome() {
    return this.monthlyRentPerUnit * this.rentUnits * this.rentEscalation
  }

  get annualRentalIncome() {
    return this.monthlyRentalIncome * 12
  }

  get vacancy() {
    return this.annualRentalIncome * this.vacancyRate
  }

  get operatingExpense() {
    return this.annualRentalIncome * this.operatingExpenseRate
  }

  get netOperatingIncome() {
    return this.annualRentalIncome - this.vacancy - this.operatingExpense
  }

  // Debt Service
  get maxAnnualDebtService() {
    return this.netOperatingIncome / this.targetDSCR
  }

  get loanAmount() {
    return this.totalDevelopmentCosts * this.rentShare * this.loanToCost
  }

  get monthlyDebtService() {
    return (
      ((this.interestRate / 12) * this.loanAmount) /
      (1 - Math.pow(1 + this.interestRate / 12, -12 * this.loanDuration))
    )
  }

  get annualDebtService() {
    return this.monthlyDebtService * 12
  }

  get maxAnnualDebtServiceOnLand() {
    return this.maxAnnualDebtService - this.annualDebtService
  }

  get maxMonthlyDebtServiceOnLand() {
    return this.maxAnnualDebtServiceOnLand / 12
  }

  get maxLoanForLand() {
    return (
      (this.maxMonthlyDebtServiceOnLand *
        (1 - Math.pow(1 + this.interestRate / 12, -12 * this.loanDuration))) /
      (this.interestRate / 12)
    )
  }

  get maxLandBudget() {
    return this.maxLoanForLand / this.loanToCost
  }

  // 4. Financial Results
  get totalResidualLandValue() {
    return this.salesResidualLandValue + this.maxLandBudget
  }

  get residualLandValuePsf() {
    return this.totalResidualLandValue / this.siteSize
  }

  // 5. Affordability Calculations
  // Rental
  // monthlyRentPerUnit calculated above
  get totalMonthlyRenterExpense() {
    if (this.type === "rent") {
      return this.monthlyRentPerUnit + this.utilitiesAllowance
    } else {
      return 0
    }
  }

  get renterAffordableIncome() {
    return (this.totalMonthlyRenterExpense / this.pctIncomeAffordable) * 12
  }

  get renterMFILevel() {
    return (
      this.renterAffordableIncome /
      (this.MFI * this.renterIncomeMultiplier * this.mfiAdjustment)
    )
  }
  // Owner
  // salesPricePerUnit calculated above
  get mortgageBalance() {
    return this.salesPricePerUnit * (1 - this.downpayment)
  }

  get monthlyMortgagePayment() {
    return (
      ((this.mortgageInterestRate / 12) * this.mortgageBalance) /
      (1 -
        Math.pow(
          1 + this.mortgageInterestRate / 12,
          -12 * this.mortgageDuration
        ))
    )
  }

  get homeownersInsurance() {
    return (this.salesPricePerUnit * this.homeownersInsuranceRate) / 12
  }

  get propertyTax() {
    return (this.salesPricePerUnit * this.propertyTaxRate) / 12
  }

  get totalHomeownerExpenses() {
    return (
      this.homeownersInsurance +
      this.propertyTax +
      this.HOA +
      this.homeownerExpenses
    )
  }

  get totalMonthlyOwnerExpenses() {
    if (this.type === "sale") {
      return this.monthlyMortgagePayment + this.totalHomeownerExpenses
    } else {
      return 0
    }
  }

  get ownerAffordableIncome() {
    return (this.totalMonthlyOwnerExpenses / this.pctIncomeAffordable) * 12
  }

  get ownerMFILevel() {
    return this.ownerAffordableIncome / (this.MFI * this.mfiAdjustment)
  }
}

export const prototypes = {
  // 1
  townhouse: {
    name: "Small Townhouses",
    type: "sale",
    variable: "sale_1500",
    variableName: "Predicted Sale Price",
    variableDesc: "3BR Townhouse, 1,500 sf",
    scalar: 1500,
    salesUnits: 2,
    // rentUnits: 0,
    // ADUUnits: 0,
    // totalUnits: 2,
    heightFl: 2.5,
    heightFt: 30,
    garageStalls: 1, // per unit
    salesUnitSizeSf: 1500,
    // rentUnitSizeSf: 0,
    grossToNetRatio: 1.0,
    numberBeds: 3,
    mfiAdjustment: 1.04,
    costPSF: 229,
    utilityHookups: "Individual for Each Unit",
    siteSizeMin: 1500, // per unit
    siteSizeMax: 4000, // per unit
    siteSizeStep: 500,
    parkingRatioMin: 1,
    parkingRatioMax: 3,
    parkingRatioStep: 1,
    parkingRatioDefault: 2,
    numberOfUnitsMin: 2,
    numberOfUnitsMax: 8,
    numberOfUnitsStep: 2,
  },
  // 2
  townhouseLarge: {
    name: "Large Townhouses",
    type: "sale",
    variable: "sale_2000",
    variableName: "Predicted Sale Price",
    variableDesc: "4BR Townhouse, 2,000 sf",
    scalar: 2000,
    salesUnits: 2,
    // rentUnits: 0,
    // ADUUnits: 0,
    // totalUnits: 2,
    heightFl: 3,
    heightFt: 35,
    garageStalls: 2, // per unit
    salesUnitSizeSf: 2000,
    // rentUnitSizeSf: 0,
    grossToNetRatio: 1.0,
    numberBeds: 4,
    mfiAdjustment: 1.16,
    costPSF: 222,
    utilityHookups: "Individual for Each Unit",
    siteSizeMin: 1500, // per unit
    siteSizeMax: 4000, // per unit
    siteSizeStep: 500,
    parkingRatioMin: 1,
    parkingRatioMax: 3,
    parkingRatioStep: 1,
    parkingRatioDefault: 2,
    numberOfUnitsMin: 2,
    numberOfUnitsMax: 8,
    numberOfUnitsStep: 2,
  },
  // 3
  duplex: {
    name: "Duplex",
    type: "rent",
    variable: "rent_1200",
    variableName: "Predicted Rent",
    variableDesc: "3BR Apartment, 1,200 sf",
    scalar: 1200,
    // salesUnits: 0,
    rentUnits: 2,
    // ADUUnits: 0,
    // totalUnits: 2,
    heightFl: 2,
    heightFt: 25,
    garageStalls: 0,
    // salesUnitSizeSf: 0,
    rentUnitSizeSf: 1200,
    grossToNetRatio: 1.0,
    numberBeds: 3,
    mfiAdjustment: 1.04,
    costPSF: 197,
    utilityHookups: "Single for Building",
    siteSizeMin: 3000,
    siteSizeMax: 12000,
    siteSizeStep: 500,
    parkingRatioMin: 0,
    parkingRatioMax: 2,
    parkingRatioStep: 0.5,
    parkingRatioDefault: 1,
  },
  // 4
  sixplex: {
    name: "Sixplex",
    type: "rent",
    variable: "rent_700",
    variableName: "Predicted Rent",
    variableDesc: "1BR Apartment, 700 sf",
    scalar: 700,
    // salesUnits: 0,
    rentUnits: 6,
    // ADUUnits: 0,
    // totalUnits: 6,
    heightFl: 2,
    heightFt: 35,
    garageStalls: 0,
    // salesUnitSizeSf: 0,
    rentUnitSizeSf: 700,
    grossToNetRatio: 0.9,
    numberBeds: 1,
    mfiAdjustment: 0.75,
    costPSF: 220,
    utilityHookups: "Single for Building",
    siteSizeMin: 6000,
    siteSizeMax: 30000,
    siteSizeStep: 1000,
    parkingRatioMin: 0,
    parkingRatioMax: 3,
    parkingRatioStep: 0.5,
    parkingRatioDefault: 1,
  },
  // 6
  fourplex: {
    name: "Fourplex",
    type: "rent",
    variable: "rent_700",
    variableName: "Predicted Rent",
    variableDesc: "1BR Apartment, 700 sf",
    scalar: 700,
    // salesUnits: 0,
    rentUnits: 4,
    // ADUUnits: 0,
    // totalUnits: 6,
    heightFl: 2,
    heightFt: 25,
    garageStalls: 0,
    // salesUnitSizeSf: 0,
    rentUnitSizeSf: 700,
    grossToNetRatio: 0.9, // to get closer to 40 x 40 footprint
    numberBeds: 1,
    mfiAdjustment: 0.75,
    costPSF: 198,
    utilityHookups: "Single for Building",
    siteSizeMin: 5000,
    siteSizeMax: 30000,
    siteSizeStep: 1000,
    parkingRatioMin: 0,
    parkingRatioMax: 3,
    parkingRatioStep: 0.5,
    parkingRatioDefault: 1,
  },
  // 7
  multiplex: {
    name: "Multiplex",
    type: "rent",
    variable: "rent_700",
    variableName: "Predicted Rent",
    variableDesc: "1BR Apartment, 700 sf",
    scalar: 700,
    // salesUnits: 0,
    rentUnits: 8,
    // ADUUnits: 0,
    // totalUnits: 6,
    heightFl: 2,
    heightFt: 25,
    garageStalls: 0,
    // salesUnitSizeSf: 0,
    rentUnitSizeSf: 700,
    grossToNetRatio: 0.9,
    numberBeds: 1,
    mfiAdjustment: 0.75,
    costPSF: 198,
    utilityHookups: "Single for Building",
    siteSizeMin: 7000,
    siteSizeMax: 30000,
    siteSizeStep: 1000,
    parkingRatioMin: 0,
    parkingRatioMax: 3,
    parkingRatioStep: 0.5,
    parkingRatioDefault: 1,
    numberOfUnitsMin: 8,
    numberOfUnitsMax: 12,
    numberOfUnitsStep: 4,
  },
}
