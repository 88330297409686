import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import MapGL, {
  Layer,
  LinearInterpolator,
  NavigationControl,
  Popup,
  Source,
  WebMercatorViewport,
} from "react-map-gl"
import bbox from "@turf/bbox"
import booleanIntersects from "@turf/boolean-intersects"
import { featureCollection } from "@turf/helpers"

import "mapbox-gl/dist/mapbox-gl.css"
import "./index.css"

import { prototypes } from "../ProForma/prototype"
import { dollarFormatter } from "../utils"

import countiesGeojson from "./counties.json"
import jurisdictionsGeojson from "./jurisdictions.json"

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYWJhZy10b29sIiwiYSI6ImNrdjlydzFkZzR2aWMyd3A2MnNiMWFvajIifQ.npIyNloKJmeyEvcZwLaMRw"

const loadSurface = () => {
  return new Promise((res, rej) => {
    import("./surface.json").then((data) => {
      res(data?.default)
    })
  })
}

const navControlStyle = {
  right: 10,
  top: 10,
}

const countiesLayerStyle = {
  id: "counties",
  type: "fill",
  paint: {
    "fill-color": "rgba(34, 34, 34, 0)",
    "fill-outline-color": "#222",
  },
}

const jurisdictionsLayerStyle = {
  id: "jurisdictions",
  type: "fill",
  paint: {
    "fill-color": "rgba(234, 180, 43, 0)",
    "fill-outline-color": "#eab42b",
  },
}

const surfaceLayerMinZoom = 7
const dynamicSurfaceMinZoom = 8

const quantile = (arr, q) => {
  const sorted = arr.slice().sort((a, b) => a - b)
  const pos = (sorted.length - 1) * q
  const base = Math.floor(pos)
  const rest = pos - base
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base])
  } else {
    return sorted[base]
  }
}

// colors from https://colorbrewer2.org/#type=sequential&scheme=Blues&n=6
const breaksAndColors = (features, property) => {
  const data = features
    .filter((item) => item.properties[property] > 0)
    .map((item) => item.properties[property])
  const colors = {
    0: "#eff3ff",
    0.2: "#c6dbef",
    0.4: "#9ecae1",
    0.6: "#6baed6",
    0.8: "#3182bd",
    1: "#08519c",
  }
  return Object.keys(colors)
    .sort()
    .map((key) => [quantile(data, key), colors[key]])
}

const styleAndLegendBreaks = (dynamicZoom, scaledSurface, mapRef, property) => {
  let features, breaks, surfaceLayerStyle
  if (dynamicZoom) {
    const map = mapRef.current && mapRef.current.getMap()
    features =
      map.getLayer("surface") &&
      map.queryRenderedFeatures({ layers: ["surface"] })
  }
  if (!features || (features && features.length === 0)) {
    features = scaledSurface.features
  }
  breaks = breaksAndColors(features, property)
  surfaceLayerStyle = {
    id: "surface",
    type: "fill",
    minzoom: surfaceLayerMinZoom,
    paint: {
      "fill-color": [
        "interpolate",
        ["linear"],
        ["get", property],
        ...breaks.flat(),
      ],
      "fill-opacity": 0.7,
    },
  }
  return { surfaceLayerStyle, breaks }
}

const Legend = (props) => {
  const { title, units, breaks } = props
  const divs = breaks.map((item, index) => {
    return (
      <div key={index}>
        <span style={{ backgroundColor: item[1] }}></span>
        {dollarFormatter.format(item[0])}
      </div>
    )
  })
  return (
    <div id="legend" className="legend">
      <h4>{title}</h4>
      {units && <p>{units}</p>}
      {divs}
    </div>
  )
}
const SurfaceMap = (props) => {
  const [delay, setDelay] = useState(500)
  const [scaledSurface, setScaledSurface] = useState({})
  const [viewport, setViewport] = useState({
    latitude: 37.7742,
    longitude: -122.2213,
    zoom: 7,
  })
  const [hoverInfo, setHoverInfo] = useState(null)
  const mapRef = useRef()
  const [surfaceLayerStyle, setSurfaceLayerStyle] = useState({
    id: "surface",
    type: "fill",
    paint: {
      "fill-opacity": 0,
    },
  })
  const [breaks, setBreaks] = useState(null)

  const { county, handleSurfaceSelection, jurisdiction, prototypeName } = props

  useEffect(() => {
    const loadSurfaceAsync = async () => {
      const scaledSurface = await loadSurface()
      const scalars = Object.fromEntries(
        Object.entries(prototypes).map(([k, v]) => [v.variable, v.scalar])
      )
      scaledSurface.features = scaledSurface.features.map((item) => {
        for (const [variable, scalar] of Object.entries(scalars)) {
          item.properties[variable] = scalar * item.properties[variable]
        }
        return item
      })
      setScaledSurface(scaledSurface)
    }
    loadSurfaceAsync()
  }, [])

  const surface = prototypeName && scaledSurface

  const prototypeProperties = useMemo(() => {
    return prototypes[prototypeName]
  }, [prototypeName])

  const interactiveLayerIds = prototypeName
    ? ["surface", "jurisdictions", "counties"]
    : ["jurisdictions", "counties"]

  useEffect(() => {
    // We need a delay the first time this has an effect because the map won't have loaded the
    // polygon data yet without it. We set the delay to 0 for subsequent renders

    setTimeout(() => {
      const variable =
        (prototypeProperties && prototypeProperties.variable) || ""
      const { surfaceLayerStyle: newSurfaceLayerStyle, breaks: newBreaks } =
        prototypeName &&
        styleAndLegendBreaks(
          viewport.zoom >= dynamicSurfaceMinZoom,
          surface,
          mapRef,
          variable
        )
      newSurfaceLayerStyle && setSurfaceLayerStyle(newSurfaceLayerStyle)
      newBreaks && setBreaks(newBreaks)
      if (newBreaks && delay === 500) {
        setDelay(0)
      }
    }, delay)
  }, [delay, viewport, prototypeName, prototypeProperties, surface])
  const onHover = useCallback(
    (event) => {
      const counties =
        event.features &&
        event.features.filter((item) => item.source === "counties")
      const county = counties && counties[0]
      const jurisdictions =
        event.features &&
        event.features.filter((item) => item.source === "jurisdictions")
      const jurisdiction = jurisdictions && jurisdictions[0]
      const bgs =
        event.features &&
        event.features.filter((item) => item.source === "surface")
      const bg = bgs && bgs[0]
      setHoverInfo({
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
        countyName: county && county.properties.NAME,
        jurisdictionName: jurisdiction && jurisdiction.properties.NAME,
        bgID: bg && bg.properties.GEOID,
        bgVariableName: bg && prototypeProperties.variableName,
        bgVariable: bg && bg.properties[prototypeProperties.variable],
      })
    },
    [prototypeProperties]
  )

  const selectedBG = hoverInfo && hoverInfo.bgID

  useEffect(() => {
    let featuresToZoomTo
    if (jurisdiction) {
      const jurisdictionLocal = jurisdiction.replace(/(City|Town) of /g, "")
      const features = jurisdictionsGeojson.features.filter(
        (item) => item.properties.NAME === jurisdictionLocal
      )
      featuresToZoomTo = features.length > 0 && features
    } else if (county) {
      const countyLocal = county.replace(/ County/g, "")
      const features = countiesGeojson.features.filter(
        (item) => item.properties.NAME === countyLocal
      )
      featuresToZoomTo = features.length > 0 && features
    }
    if (featuresToZoomTo) {
      // calculate the bounding box of the features
      const [minLng, minLat, maxLng, maxLat] = bbox(
        featureCollection(featuresToZoomTo)
      )

      setViewport((v) => {
        // construct a viewport instance from the current state
        const vp = new WebMercatorViewport(v)
        const { longitude, latitude, zoom } = vp.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: 40,
          }
        )
        return {
          ...v,
          longitude,
          latitude,
          zoom,
          transitionInterpolator: new LinearInterpolator(),
          transitionDuration: 2000,
        }
      })
    }
  }, [county, jurisdiction])

  useEffect(() => {
    let featuresToZoomTo
    let jurisdictionSurface
    if (jurisdiction) {
      const jurisdictionLocal = jurisdiction.replace(/(City|Town) of /g, "")
      const features = jurisdictionsGeojson.features.filter(
        (item) => item.properties.NAME === jurisdictionLocal
      )
      featuresToZoomTo = features.length > 0 && features
      jurisdictionSurface = prototypeName && {
        ...surface,
        features: surface.features.filter((item) =>
          booleanIntersects(item, featuresToZoomTo[0])
        ),
      }
    }
    if (jurisdictionSurface) {
      // // limit what we show
      // setSurface(jurisdictionSurface)
      // send data upstream
      handleSurfaceSelection(jurisdictionSurface)
    }
  }, [
    surface,
    prototypeProperties,
    county,
    jurisdiction,
    prototypeName,
    handleSurfaceSelection,
  ])

  return (
    <div className="rent-surface-map" aria-hidden={true}>
      <h2>ABAG Map Jurisdictions</h2>
      <div style={{ height: "400px", width: "100%" }}>
        <MapGL
          {...viewport}
          ref={mapRef}
          width="100%"
          height="100%"
          onViewportChange={(v) => setViewport(v)}
          onHover={onHover}
          interactiveLayerIds={interactiveLayerIds}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          {prototypeName && viewport.zoom >= surfaceLayerMinZoom && breaks && (
            <Legend
              title={prototypeProperties.variableName}
              units={prototypeProperties.variableDesc}
              breaks={breaks}
            />
          )}
          <NavigationControl style={navControlStyle} />
          {prototypeName && (
            <Source id="surface" type="geojson" data={surface}>
              <Layer {...surfaceLayerStyle} beforeId="counties" />
            </Source>
          )}
          <Source id="counties" type="geojson" data={countiesGeojson}>
            <Layer {...countiesLayerStyle} />
          </Source>
          <Source id="jurisdictions" type="geojson" data={jurisdictionsGeojson}>
            <Layer {...jurisdictionsLayerStyle} />
          </Source>
          {hoverInfo && hoverInfo.countyName && (
            <Popup
              longitude={hoverInfo.longitude}
              latitude={hoverInfo.latitude}
              closeButton={false}
              className="bg-info"
            >
              {hoverInfo.jurisdictionName ? (
                <strong>
                  {hoverInfo.jurisdictionName}, {hoverInfo.countyName} County
                </strong>
              ) : (
                <strong>Unincorporated {hoverInfo.countyName} County</strong>
              )}
              {selectedBG && (
                <span>
                  <br />
                  {hoverInfo.bgVariableName}:{" "}
                  {dollarFormatter.format(hoverInfo.bgVariable)}
                </span>
              )}
            </Popup>
          )}
        </MapGL>
      </div>
      {/* <h5>Map Center</h5>
      <p>
        Latitude: {viewport.latitude.toFixed(4)}
        <br />
        Longitude: {viewport.longitude.toFixed(4)}
        <br />
        Zoom: {viewport.zoom.toFixed(4)}
      </p> */}
    </div>
  )
}

export default SurfaceMap
